// Base styles

::-moz-selection { background: $select; }
::selection { background: $select; }


html,
body {
	overflow-x: hidden; // prevent vertical scrolling (popover rendering/repositioning)
	margin: 0;
}

html {
	font-size: 62.5%; //is equal to 10px - https://www.sitepoint.com/stop-maiming-bodies-the-perils-of-pixel-font-size/
	box-sizing: border-box;
	min-height: 100vh;

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
}

body {
	font-family: $font-family-main;
	font-size: 1.6rem; // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
	line-height: 1;
	position: relative;
	width: 100%;
	min-height: 100vh;
	margin: 0 auto;
	padding: 0;
	color: $text;
	background: #fff;

	 > svg {
		position: absolute;

		&:first-child {
			display: block;
		}
	}
}

.overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 100%;
	display: block;
	cursor: pointer;
	transition: all 0.3s;
	background: $white;

	@include mq('desktop-min') {
		display: none;
	}

	body.nav-open & {
		left: 0;
	}
}

a,
a:visited {
	transition: color 0.3s;
	text-decoration: $link-decoration;
}

a:hover {
	text-decoration: $link-hover-decoration;
}

.cf:before,
.cf:after {
	display: table;
	content: ' ';
}
.cf:after {
	clear: both;
}
