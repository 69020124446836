//
// Icons
// --------------------------------------------------

.icon {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 35px;
	transition: all 0.3s;
	fill: $black;

	&:hover {
		fill: $primary;
	}
}
