.cookie-layer {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    //cursor: pointer;
    opacity: 0;
    visibility: hidden;
    z-index: -10000;
    //transition: all .5s;

    &.is-active {
        z-index: 10000;
        opacity: 1;
        visibility: visible;
    }

    .cookie-content {
        display: block;
        position: fixed;
        width: 100%;
        max-width: 300px;
        height: auto;
        background: $white;
        color:$color-text;
        padding: 20px;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        cursor: auto;
        font-weight: 300;

        @include mq('tablet-min') {
            padding: 40px;
        }
    }

    .btn {
        margin-top: 20px;
        width: 100%;
        max-width: 100%;
    }
}
