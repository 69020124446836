.note {
    display: block;
    position: relative;
    padding: 0 20px 20px;
    width: 100%;
    margin: 0 auto;
    max-width: 1020px;
    font-weight: 300;
    color:$primary-dark;
    font-size: 1.8rem;
}
