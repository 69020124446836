//
// Forms
// --------------------------------------------------
form {

    input,
    select,
    button,
    textarea {
        font-family: $font-family-main;
        appearance: none;
        -webkit-appearance: none;
        border-radius:0;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    *::placeholder {
        color: transparentize($black,0.5);
    }

    input[type=text] {
        min-height: 48px;
    }

    input,
    textarea {
        border:1px solid #c0c0c0;
        background: $white;
        width: 100%;
        padding: 15px 15px 14px;
        max-width: 100%;
        max-height: 300px;
        font-size: 14px;
    }

    input[type=checkbox] {
        position: absolute;
        top: 6px;
        left: 2px;
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;

        & + label {
            position: relative;
            top: auto;
            left: auto;
            display: flex;
            width: 100%;
            padding: 0;
            cursor: pointer;
            align-items: flex-start;

            &:before {
                position: relative;
                z-index: 1;
                top: 0;
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                padding: 0;
                content: '';
                border:1px solid #c0c0c0;
                border-radius: 0;
                background: $white;
                flex-shrink: 0;
            }

            &:after {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 30px;
                height: 30px;
                padding: 0;
                content: '';
                background: $primary url('/assets/images/icons/check.svg') no-repeat center center;
                background-size: 18px;
                display: none;
                flex-shrink: 0;
            }

            p {
                margin: 0;
            }
        }

        &:checked + label:after {
            display: block;
        }
    }
}

.row {
    display: block;
    position: relative;
    margin-bottom: 20px;
}

.success {
    display: block;
    position: relative;
    max-width: 600px;
    white-space: pre-line;
}
