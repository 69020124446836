.text-img {
    position: relative;
    width: 100%;
    margin: 0 auto 60px;
    padding: 40px;
    max-width: 800px;
    display: flex;
    flex-direction: column;

    @include mq('tablet-min') {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
    }

    figure {
        display: block;
        position: relative;
        width: 100%;
        max-width: 275px;
        height: auto;
        margin: 0 0 30px;

        @include mq('tablet-min') {
            margin-right: 25px;
        }

        img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
        }
    }

    .text {
        display: block;
        position: relative;
        width: 100%;
        max-width: 500px;
        height: auto;
    }

    h1 {
        display: block;
        position: relative;
        margin: 0 0 20px;
        padding: 0;
        color:$secondary;
        font-size: 3rem;
        line-height: 1.2;
        font-weight: 300;

        @include mq('desktop-min') {
            font-size: 4.8rem;
        }
    }

    p {
        color:$secondary;
        font-weight: 300;
    }
}
