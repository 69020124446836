.gallery {
    display: block;
    position: relative;
    margin: 0 auto 50px;
    padding: 0;
    width: 100%;
    max-width: 1020px;

    @include mq('desktop-min') {
        display: flex;
        flex-wrap: wrap;
    }

    h4 {
        display: block;
        position: relative;
        margin-bottom: 20px;
        margin-top: 0;
        padding: 0 20px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 1.4rem;
        width: 100%;
        flex: 0 0 100%;
        letter-spacing: 1px;

        @include mq('desktop-min') {
            padding: 0;
        }
    }

    img {
        display: block;
        position: relative;
        padding: 0;
        margin: 0 0 20px;
        width: 100%;
    }

    .col1 {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        width: 100%;

        @include mq('desktop-min') {
            width: 40%;
        }
    }

    .col2 {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        width: 100%;

        @include mq('desktop-min') {
            width: 60%;
            padding-left: 20px;
        }
    }
}
