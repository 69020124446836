//
// Main Navigation
// --------------------------------------------------
.navigation {
	position: static;
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;

	@include mq('desktop-min') {
		position: relative;
        display: flex;
        align-items: flex-end;
        width: auto;
        padding: 0;
        margin-left: auto;
        height: 30px;
        transition: all .3s;
	}

	* {
		user-select: none;
	}

	& > ul {
		position: absolute;
		z-index: 200;
        width: 90%;
		top: 45vh;
		left: 50%;
		display: block;
		visibility: hidden;
		margin: 0;
		padding: 0;
		list-style-type: none;
		transition: all 0.3s;
		transform: translate(-50%,-50%);
		text-align: center;
		opacity: 0;

		@include mq('desktop-min') {
			position: relative;
			top: auto;
			left: auto;
			visibility: visible;
			width: 100%;
			height: auto;
			transition: all 0.3s;
			transform: none;
			text-align: right;
			opacity: 1;
            display: flex;
            justify-content: flex-start;
		}
	}

	& > ul > li {
		line-height: 1;
		position: relative;
		display: block;
		min-height: 60px;
		margin-bottom: 5px;
		cursor: pointer;

		@include mq('handheld-max') {
			transition: all 0.7s;
			transform: translateY(-50%);
			opacity: 0;
		}
		@include mq('desktop-min') {
			display: inline-block;
			height: auto;
			min-height: 0;
			margin: 0 0 0 20px;
			border-bottom: none;
			background: none;
		}

        @include mq('desktop-xl-min') {
            margin: 0 0 0 40px;
        }

		&:first-child {
			margin-left: 0;
		}

		&:hover > a,
		&:hover > .nolink {

			&:before {
				@include mq('desktop-min') {
					width: 100%;
				}
			}
		}
	}

	& > ul li.is-active {
		& > a:before,
		& > .nolink:before {
			width: 100%;
		}

        a {
            color:$secondary;
        }
	}

	a,
    .nolink {
		font-family: $font-family-main;
		font-size: 3rem;
		font-weight: 500;
		position: relative;
		display: block;
		padding: 20px;
		letter-spacing: 2px;
		color: $primary;
        text-transform: uppercase;

        &:hover {
            color:$secondary;

            @include mq('desktop-min') {
                color:$secondary;
            }
        }

		@include mq('desktop-min') {
			font-size: 1.6rem;
			padding: 0;
            font-weight: 300;
            color:$primary;
            letter-spacing: 1px;
		}

		@include mq('desktop-xl-min') {
			font-size: 1.6rem;
		}
	}

	ul li a,
	ul li .nolink {
		position: relative;
	}

	// MOBILE NAVIGATION STYLES
	body.nav-open & {
		display: block;
	}

	.btn-nav {
		position: absolute;
		z-index: 200;
		top: 10px;
		right: 20px;
		display: block;
		width: 30px;
		height: 20px;
		margin: 0;
		padding: 8px 0;
		cursor: pointer;
		transition: all 0.3s;

		@include mq('desktop-min') {
			display: none;
		}

		span {
			position: absolute;
			top: 8px;
			left: 0;
			display: block;
			width: 26px;
			height: 2px;
			background: $primary;
		}

		span:before {
			position: absolute;
			top: -8px;
			left: 0;
			display: block;
			width: 30px;
			height: 2px;
			content: '';
			transform-origin: 25px 6px;
			background: inherit;
		}

		span:after {
			position: absolute;
			top: 8px;
			left: 0;
			display: block;
			width: 30px;
			height: 2px;
			content: '';
			transform-origin: 24px -2px;
			background: inherit;
		}

		&.is-active span {
			background: none;

			&:before {
				animation: menuTop 0.3s;
				animation-fill-mode: forwards;
				background: $primary;
			}

			&:after {
				animation: menuBot 0.3s;
				animation-fill-mode: forwards;
				background: $primary;
			}
		}

		&.is-inactive span {
			&:before {
				animation: menuTop-reverse 0.3s;
				animation-fill-mode: forwards;
			}

			&:after {
				animation: menuBot-reverse 0.3s;
				animation-fill-mode: forwards;
			}
		}

		body.nav-open & {
			span {
				background: none;
			}
		}
	}

	body.nav-open & {
		ul {
			visibility: visible;
			opacity: 1;
		}

		& > ul > li {
			@include mq('handheld-max') {
				transition-delay: 0.3s;
				transform: translateY(0);
				opacity: 1;
			}

			&:nth-child(2) {
				@include mq('handheld-max') {
					transition-delay: 0.4s;
				}
			}

			&:nth-child(3) {
				@include mq('handheld-max') {
					transition-delay: 0.5s;
				}
			}

			&:nth-child(4) {
				@include mq('handheld-max') {
					transition-delay: 0.6s;
				}
			}

			&:nth-child(5) {
				@include mq('handheld-max') {
					transition-delay: 0.7s;
				}
			}

			&:nth-child(6) {
				@include mq('handheld-max') {
					transition-delay: 0.8s;
				}
			}

			&:nth-child(7) {
				@include mq('handheld-max') {
					transition-delay: 0.9s;
				}
			}

			&:nth-child(8) {
				@include mq('handheld-max') {
					transition-delay: 1s;
				}
			}
		}
	}
}

body.is-sticky > header nav .btn-nav span {
    background: $primary;
}
