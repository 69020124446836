//
// Helper classes
// --------------------------------------------------
// Media Queries
@mixin mq($point) {
    @if $point == mobile-max {
		@media #{$bp-mobile-max} {
			@content;
		}
	} @else if $point == mobile-min {
		@media #{$bp-mobile-min} {
			@content;
		}
	} @else if $point == tablet-min {
		@media #{$bp-tablet-min} {
			@content;
		}
	} @else if $point == tablet-max {
		@media #{$bp-tablet-max} {
			@content;
		}
    } @else if $point == mobile-l-max {
        @media #{$bp-mobile-l-max} {
            @content;
        }
    } @else if $point == mobile-xl-max {
		@media #{$bp-mobile-xl-max} {
			@content;
		}
	} @else if $point == desktop-min {
		@media #{$bp-desktop-min} {
			@content;
		}
	} @else if $point == handheld-max {
		@media #{$bp-handheld-max} {
			@content;
		}
	} @else if $point == ipad-max {
		@media #{$bp-ipad-max} {
			@content;
		}
	} @else if $point == desktop-l-min {
		@media #{$bp-desktop-l-min} {
			@content;
		}
	} @else if $point == desktop-xl-min {
		@media #{$bp-desktop-xl-min} {
			@content;
		}
	}
}

//Decorative font
@mixin headline($size) {
	font-family: $font-family-decorativ;
	font-size: decimal-round(($size/$font-deco-base), 1);
	font-weight: 300;
	line-height: 120%;
}
