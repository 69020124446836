//
// Button
// --------------------------------------------------

.btn {
	font-family: $font-family-main;
	font-size: 1.5rem;
	display: inline-block;
	width: auto;
	padding: 15px 45px 13px;
	cursor: pointer;
	transition: all 0.3s;
	text-align: center;
    background: $primary;
	border: 2px solid $primary;
	color: $white;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 600;
    height: 45px;
    border-radius:3px;

	&:hover {
		background: $white;
        color:$primary;
	}

    &.btn-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 15px 45px 11px;

        &:hover {
            .icon {
                fill:$primary;
            }
        }

        .icon {
            fill:$white;
            width: 22px;
            height: 22px;
            margin-left: 10px;
            top: -3px;
        }
    }
}
