.boxes-2col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto 100px;
    padding: 0 20px;

    @include mq('desktop-min') {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
    }

    strong {
        font-weight: 500;
        color:$primary;
        display: block;
        margin-bottom: 10px;
        position: relative;
        font-size: 2.4rem;
    }

    p {
        display: block;
        padding: 20px;
        border: solid 1px #e5e5e5;
        position: relative;
        margin: 0 0 20px;
        width: 100%;
        white-space: pre-line;
        font-size: 2rem;
        max-width: 500px;
        font-weight: 300;

        @include mq('desktop-min') {
            width: calc(50% - 10px);
        }
    }
}
