.legal {
    display: block;
    width: 100%;
    padding: 30px 20px 0;
    max-width: 1020px;
    margin: 0 auto;

    @include mq('desktop-min') {
        padding-top: 50px;
    }

    h1 {
        color:$primary;
        display: block;
        position: relative;
        font-size: 2.4rem;
        line-height: 1.4;
        font-family: $font-family-special;
        font-weight: 600;
        margin: 0 0 30px;
        text-transform: uppercase;

        @include mq('tablet-min') {
            font-size: 3rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        margin-top: 30px;
    }

    p {
        font-weight: 300;
    }
}
