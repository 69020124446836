//
// Header
// --------------------------------------------------
body > header {
	position: fixed;
	z-index: 10;
	left: 0;
	display: flex;
    align-items: center;
	width: 100%;
    min-height: 70px;
	height: auto;
	margin: 0;
	padding: 10px 0;
	transition: background 0.3s, height 0.3s;
	background: transparent;
    background: $white;

    @include mq('desktop-min') {
        height: 105px;
        padding: 20px 0;
        align-items: flex-end;
        transition: all .3s;
    }

    .grid-wide {
        @include mq('desktop-min') {
            display: flex;
            align-items: center;
        }
    }

	.logo {
		position: relative;
		display: block;
        height: 40px;
		width: 220px;
        overflow: hidden;
		margin: 0;
		padding: 0;
        transition: all .3s;

        @include mq('desktop-min') {
            height: 65px;
            width: 350px;
        }

        img {
            display: block;
            height: 100%;
            width: auto;
            padding: 0;
            margin: 0;
        }
	}
}

body.nav-open > header,
body.is-sticky.nav-open > header {

    .logo {
        z-index: 200;
    }
}

body.is-sticky > header {
    @include mq('desktop-min') {
        background: $white;
        height: 70px;
        padding: 15px 0;
        box-shadow: 0 0 20px rgba(0,0,0,0.2);

        .logo {
            height: 40px;
            width: 60px;
            overflow: hidden;
        }

        nav {
            height: 40px;
            align-items: center;
        }

        nav a,
        nav .nolink {
            color:$primary;

            &:hover {
                color:$secondary;
            }
        }
    }
}
