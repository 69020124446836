//
// Grid system
// --------------------------------------------------

.grid-wide {
	position: relative;
	display: block;
	width: 100%;
	max-width: 1600px;
	height: auto;
	margin: 0 auto;
	padding: 0 20px;

    @include mq('tablet-min') {
        padding: 0 40px;
    }

    @include mq('desktop-xl-min') {
        padding: 0 80px;
    }
}

.grid-small {
    position: relative;
    display: block;
    width: 100%;
    max-width: 1024px;
    height: auto;
    margin: 0 auto;
    padding: 0 20px;

    @include mq('tablet-min') {
        padding: 0 40px;
    }
}

.grid-content {
    position: relative;
    display: block;
    width: 100%;
    max-width: 900px;
    height: auto;
    margin: 0;
    padding: 0;
}

.grid-full {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	margin: 0;

	&.is-primary {
		background: $primary;
	}

	&.is-grey {
		background: #f4f4f4;
	}
}

.row {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;

	@extend .cf;
}
