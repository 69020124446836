/* ubuntu-300 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: local('Ubuntu Light'), local('Ubuntu-Light'),
    url('/assets/fonts/ubuntu/ubuntu-v14-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/ubuntu/ubuntu-v14-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-500 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
    url('/assets/fonts/ubuntu/ubuntu-v14-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/ubuntu/ubuntu-v14-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-700 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
    url('/assets/fonts/ubuntu/ubuntu-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/ubuntu/ubuntu-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
