//
// Animations
// --------------------------------------------------
@keyframes fadeFromTop {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes menuTop {
    0% {
        top: -8px;
    }
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-45deg);
    }
}

@keyframes menuBot {
    0% {
        top: 8px;
    }
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@keyframes menuTop-reverse {
    0% {
        transform: rotate(-45deg);
    }
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        top: -8px;
    }
}

@keyframes menuBot-reverse {
    0% {
        transform: rotate(45deg);
    }
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        top: 8px;
    }
}