blockquote {
    display: block;
    position: relative;
    text-align: center;
    color:$white;
    line-height: 1.2;
    font-size: 3rem;
    max-width: 1000px;
    padding: 30px 20px;
    margin: 0 auto 50px;
    font-weight: 300;

    @include mq('desktop-min') {
        font-size: 4.8rem;
        padding: 70px 50px;
        margin-bottom: 120px;
    }
}
