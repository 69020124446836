#map {
    position: relative;
    display: block;
    width: 100%;
    height: 400px;

    @include mq('tablet-min') {
        height: 600px;
    }
}
