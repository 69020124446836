.box-full {
    display: block;
    position: relative;
    padding: 20px;
    border: solid 1px #e5e5e5;
    margin: 0 auto 50px;
    font-size: 2rem;
    max-width: 1020px;
    width: calc(100% - 40px);

    @include mq('desktop-min') {
        margin-bottom: 80px;
        width: 100%;
    }

    h2 {
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        margin-bottom: 20px;
        position: relative;
        font-size: 2.4rem;
    }

    h3 {
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        margin-bottom: 20px;
        position: relative;
        font-size: 2.4rem;
    }

    ul, li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;
        position: relative;
        font-size: 1.8rem;
        font-weight: 300;

        &:not(:first-child) {
            padding-left: 20px;

            &:before {
                content: '';
                height: 6px;
                width: 6px;
                position: absolute;
                left: 4px;
                top: 7px;
                display: block;
                background: $text;
            }
        }
    }
}

.content-boxes {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1020px;
    flex-wrap: wrap;
    margin: 0 auto 50px;
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;

    @include mq('desktop-min') {
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 80px;
        padding: 0;
    }
}

.box-small {
    display: block;
    position: relative;
    padding: 30px 20px;
    border: solid 1px #e5e5e5;
    margin: 0 auto 20px;
    width: 100%;
    font-size: 2rem;
    max-width: 500px;

    @include mq('desktop-min') {
        width: calc(50% - 10px);
        margin: 0 0 20px;
    }

    h3 {
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        margin-bottom: 20px;
        position: relative;
        font-size: 2.4rem;
        margin-top: 0;
    }

    p {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 300;
    }

    & > strong {
        font-weight: 300;
        display: block;
        position: relative;
        white-space: pre-line;
        color:$primary-dark;
        margin-top: 10px;
        line-height: 1.4;
    }
}

