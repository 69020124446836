// Footer
// --------------------------------------------------
body > footer {
	display: block;
	width: 100%;
	padding: 40px 20px;
    margin: 0;
    border-top:1px solid #e8efec;
    text-align: center;
    font-weight: 300;
    color:$secondary;

	ul {
		position: relative;
		display: block;
		margin: 0 0 10px;
		padding: 0;
		list-style-type: none;
        width: 100%;
        flex-direction: row;
        text-align: center;

        @include mq('tablet-min') {
            display: flex;
            align-items: center;
            justify-content: center;
        }
	}

	ul li {
		display: block;
		width: auto;
		margin: 0;
        color:$secondary;
        letter-spacing: 1px;

        @include mq('tablet-min') {
            margin: 0 30px 0 0;
        }

        &:last-child {
            margin-right: 0;
        }
	}

	a {
		font-weight: 500;
		position: relative;
		display: inline-block;
		transition: all 0.3s;
        margin: 0 0 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color:$secondary;
        font-size: 1.4rem;

        @include mq('tablet-min') {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
            color:$primary;
        }
	}

    p {
        color:$secondary;
    }
}
