.stage {
	position: relative;
	display: block;
    width: 100%;
    height: 320px;
    margin: 0 0 100px;
    padding: 0;
    background: $primary;

    @include mq('tablet-min') {
        height: 400px;
    }

    @include mq('desktop-min') {
        height: 500px;
        margin-bottom: 120px;
    }

    .grid-wide {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @include mq('desktop-min') {
            padding: 0 80px;
        }
    }

    figure {
        display: block;
        position: relative;
        width: auto;
        margin: 0 0 30px;
        padding: 0;

        @include mq('desktop-min') {
            margin-bottom: 50px;
        }

        img {
            position: relative;
            display: block;
            padding: 0;
            margin: 0;
            height: 200px;
            width: auto;

            @include mq('tablet-min') {
                height: 300px;
            }
        }
    }

    h2 {
        display: block;
        position: relative;
        color:$white;
        font-size: 2.4rem;
        font-weight: 500;
        margin: 0;
        line-height: 1.2;

        @include mq('tablet-min') {
            font-size: 3rem;
        }

        @include mq('desktop-min') {
            font-size: 4rem;
        }
    }

    .js-jumper {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -25px);
        top: 100%;
        font-size: 1.8rem;
        color:$text;
        text-align: center;

        @include mq('desktop-min') {
            font-size: 2rem;
        }

        .icon {
            background: #95a30d;
            border-radius:100%;
            overflow: hidden;
            display: block;
            margin: 0 auto 20px;
            fill:$white;
            padding: 10px;
            width: 50px;
            height: 50px;
        }
    }
}
