//
// Typography
// --------------------------------------------------
a:focus {
    outline: none;
    box-shadow: none;
}

a {
    color: $black;

    &:hover {
        color: $primary;
    }
}

p, li {
	font-size: 1.6rem;
	line-height: 1.4;
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
    color:$color-text;
    letter-spacing: .5px;

    a {
        color:$primary;
        font-size: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}

li {
    display: list-item;
    margin-bottom: 10px;
}

h2 {
    font-size: 1.8rem;
    margin: 0 0 20px;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-family: $font-family-main;
    color:$text;

    @include mq('tablet-min') {
        font-size: 2.5rem;
    }
}

h3 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 15px;
}

h4 {
    margin-bottom: 10px;
    margin-top: 0;
    font-size: 2rem;
}

article {

    .block-list {
        list-style-type: none;
        display: block;
        position: relative;
        margin: 0;
        padding: 0;

        li {
            padding: 0;
            display: block;
            margin-bottom: 50px;

            &:first-child {
                margin-top: 50px;
            }
        }
    }

    & > .grid-wide:first-child .grid-content:last-child {
        margin-bottom: 0;
    }

    a:not(.btn) {
        color:$primary;
    }

    .rte-link {
        display: flex;
        position: relative;
        color:$primary;
        margin-top: 15px;
        align-items: center;

        &:hover .icon {
            transform: translateX(3px);
        }

        .icon {
            width: 18px;
            height: 18px;
            display: inline-block;
            margin: 0 0 0 3px;
            padding: 0;
            fill:$primary;
            position: relative;
            top: -2px;

        }
    }

    p {
        line-height: 1.6;
    }

    strong {
        font-weight: 600;
    }

    .btn {
        margin-top: 30px;
    }

    .btn-icon {
        margin-top: 20px;
    }

    .grid-content:last-child {
        margin-bottom: 150px;

        hr {
            display: none;
        }
    }

    hr {
        border:0;
        border-top:1px solid #dbdbdb;
        width: 100%;
        display: block;
        margin: 30px 0;
        padding: 0;

        @include mq('tablet-min') {
            margin: 50px 0;
        }
    }
}
