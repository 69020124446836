// Main
// --------------------------------------------------
main {
	position: relative;
	display: block;
	width: 100%;
    padding-top: 70px;

    @include mq('desktop-min') {
        padding-top: 105px;
    }
}

.content {
    .intro {
        margin: 0 auto 50px;

        @include mq('desktop-min') {
            margin-bottom: 50px;
        }
    }

    .grid-content:not(section):last-child {
        margin-bottom: 150px;

        hr {
            display: none;
        }
    }
}
