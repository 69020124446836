.contact {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 50px auto;
    padding: 0 20px;

    @include mq('desktop-min') {
        flex-direction: row;
        margin: 100px;
    }

    .content {
        display: block;
        position: relative;
        padding: 30px 0;
        width: 100%;

        @include mq('tablet-min') {
            padding: 50px 30px;
        }

        @include mq('desktop-min') {
            width: 50%;
            padding: 30px 50px;
        }

        @include mq('desktop-xl-min') {
            padding: 100px 100px 100px 50px;
        }

        h1 {
            display: block;
            position: relative;
            font-size: 3rem;
            margin: 0 0 20px;
            font-weight: 300;
            white-space: pre-line;

            strong {
                font-weight: 400;
            }
        }

        p {
            display: block;
            margin: 0 0 20px;
            white-space: pre-line;
        }

        .link {
            color:$color-text;
            font-size: 1.4rem;

            .icon {
                fill:$color-text;
            }
        }
    }

    form, .success {
        width: 100%;
        margin-bottom: 30px;
        max-width: 550px;

        @include mq('desktop-min') {
            width: 50%;

        }
    }

    .btn {
        float: right;
        padding: 13px 45px 15px;
    }

    .grid-wide {
        display: flex;
        flex-direction: column;

        @include mq('desktop-min') {
            flex-direction: row;
        }
    }

    .block {
        display: block;
        position: relative;
        width: 100%;

        &.block-double {
            display: flex;
            flex-direction: column;

            @include mq('desktop-min') {
                flex-direction: row;
                justify-content: space-between;
            }

            .row {
                width: 100%;

                @include mq('desktop-min') {
                    width: calc(50% - 10px);
                }
            }
        }
    }

    textarea {
        min-width: 100%;
        min-height: 200px;
        max-width: 100%;
        max-height: 200px;
    }

    .row-special{display: none !important;}

    .row:not(.row-checkbox) label {
        font-size: 1.1rem;
        position: absolute;
        background: $white;
        padding: 2px;
        left: 15px;
        top: 0;
        transform: translateY(-50%);
        font-weight: 600;
    }
}
