.img-quote {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 1020px;
    background: $primary;
    color:$white;
    margin: 0 auto 20px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    @include mq('desktop-min') {
        flex-direction: row;
    }

    blockquote {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 60px 25px 60px 25px;
        width: 100%;
        margin: 0;

        @include mq('desktop-min') {
            width: 50%;
            padding: 80px 50px 80px 25px;
        }

        strong {
            display: block;
            position: absolute;
            top: 25px;
            left: 25px;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 1px;
        }

        p {
            display: block;
            font-size: 3rem;
            color: $white;
            position: relative;
            padding: 0;
            margin: 0;
            text-align: left;
            font-weight: 300;
            line-height: 1.2;

            @include mq('desktop-min') {
                font-size: 4rem;
            }
        }

        cite {
            display: block;
            position: absolute;
            bottom: 25px;
            left: 25px;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 1px;
            font-style: normal;
        }
    }



    figure {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        overflow: hidden;

        @include mq('desktop-min') {
            width: 50%;
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;

        @include mq('desktop-min') {
            position: absolute;
            left: 50%;
            top: 50%;
            height: 100%;
            width: auto;
            transform: translate(-50%,-50%);
        }
    }

    &.img-quote-text {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        padding: 0;

        @include mq('desktop-min') {
            flex-direction: row;
        }

        .text {
            display: block;
            position: relative;
            padding: 20px;
            width: 100%;

            @include mq('desktop-min') {
                padding: 25px;
                width: 50%;
            }
        }

        .text blockquote {
            padding-left: 0;
            width: 100%;
        }

        .text blockquote strong,
        .text blockquote cite {
            left: 0;
        }

        .text > strong {
            font-size: 1.8rem;
            margin-bottom: 30px;
            display: block;
            position: relative;
            line-height: 1.4;
            font-weight: 500;
        }

        .text > p {
            font-size: 1.8rem;
            margin: 0;
            display: block;
            position: relative;
            line-height: 1.4;
            color:$white;
            font-weight: 300;
        }

    }
}

